import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import { getToken } from '@/utils/cookies'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    component: (resolve) => require(['@/layout/index'], resolve),
    children: [
      { path: '/', redirect: '/home' },
      {
        path: '/home',
        name: 'home',
        component: (resolve) => require(['@/views/home/index'], resolve),
        meta: {
          // keepAlive: true
        }
      },

      {
        path: '/order',
        name: 'order',
        component: (resolve) => require(['@/views/order/index'], resolve),
        meta: {
          keepAlive: true,
          scrollTop: 0
        }
      },


      {
        path: '/mine',
        name: 'mine',
        component: (resolve) => require(['@/views/mine/index'], resolve),
        meta: {
          // keepAlive: true
        }
      }
    ]
  },

  {
    path: '/hotelList',
    name: 'hotelList',
    component: (resolve) => require(['@/views/hotelList/index'], resolve),
    meta: {
      keepAlive: false
    }

  },

  {
    path: '/hotelDetail',
    name: 'hotelDetail',
    component: (resolve) => require(['@/views/hotelDetail/index'], resolve),
    meta: {
      keepAlive: false
    }

  },
  {
    path: '/hotelDetail/hotelRim',
    name: 'hotelRim',
    component: (resolve) => require(['@/views/hotelDetail/hotelRim'], resolve),
    meta: {
      keepAlive: true
    }

  },

  {
    path: '/hotelFill',
    name: 'hotelFill',
    component: (resolve) => require(['@/views/hotelFill/index'], resolve),
    meta: {
      keepAlive: true
    }
  },


  {
    path: '/hotelConfirm',
    name: 'hotelConfirm',
    component: (resolve) => require(['@/views/hotelConfirm/index'], resolve),
    meta: {
      keepAlive: false
    }
  },

  {
    path: '/orderDetail',
    name: 'orderDetail',
    component: (resolve) => require(['@/views/order/detail.vue'], resolve),
    meta: {
      // keepAlive: true
    }
  },

  // {
  //   path: '/login',
  //   name: 'login',
  //   component: (resolve) => require(['@/views/mine/login/index'], resolve)
  // }


  {
    path: '/setapi',
    name: 'Setapi',
    component: () => import('@/views/mine/setUrl.vue')
  },

  {
    path: '/bywexin',
    name: 'bywexin',
    component: () => import('@/views/mine/bywexin.vue')
  },
  {
    path: '/mine/points',
    name: 'minePoints',
    component: (resolve) => require(['@/views/mine/points/index'], resolve),
    meta: {
      // keepAlive: true
    }
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  const href = window.location.href;
  if (href.indexOf('/?code') > -1) {
    const urlArr = href.split('/?')
    const leftUrl = urlArr[0] + '/#/'
    const rightUrlArr = urlArr[1].split('#/')
    const queryObj = {}
    // 获取code和state参数
    rightUrlArr[0]
      .split('&')
      .map((item) => {
        const splitStr = item.split('=')
        return {
          key: splitStr[0],
          value: splitStr[1],
        }
      })
      .forEach((item) => {
        queryObj[item.key] = item.value
      })
    // console.log("code-----------",queryObj["code"]);
    // console.log("leftUrl------",leftUrl);
    // console.log("rightUrlArr[1]-----",rightUrlArr[1]);
    location.href = `${leftUrl}${rightUrlArr[1]}?logincode=${queryObj["code"]}`;
  } else {
    next()
  }
})

export default router
