import { State } from './state'

const mutations = {
  setUserInfo(state: State, data: string) {
    state.userInfo = data
    sessionStorage.setItem('userInfo', data)
  },

  setOrderStatus(state: State, data: string) {
    state.orderStatus = data
  }
}

export default mutations
