const state = {
  userInfo: {},
  orderStatus: ''
}

export class State {
  public userInfo?: any
  public orderStatus?: any
}

export default state
