import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/index'
import 'vant/lib/index.css'
import '@assets/scss/index.scss'
import './config/rem.js'
import * as moment from 'moment'
import axios from 'axios'

Vue.config.productionTip = false

Vue.prototype.$moment = moment
Vue.prototype.$axios = axios
// import { Lazyload } from 'vant';
// Vue.use(Lazyload, {
//   lazyComponent: true,
// });

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
