import { State } from './state'

const getters = {
  getUserInfo(state: State) {
    return JSON.parse(sessionStorage.getItem('userInfo')) || state.userInfo || ''
  },

  getOrderStatus(state: State) {
    console.log('state',state);
    
    return state.orderStatus
  }
}

export default getters
